import React, { useEffect } from 'react'
import { Layout } from '../../components/AppLayout'
import styled from 'styled-components'
import { AppSupplierManagementSolutionBanner } from '../../components/AppSupplierManagementSolutionBanner'
import { AppSupplierManagementSolutionCard } from '../../components/AppSupplierManagementSolutionCard'
import { AppSupplierManagementSolutionCollapsible } from '../../components/AppSupplierManagementSolutionCollapsible'
import Scroll from 'react-scroll'
import { RDHelmet } from '../../components/RDHelmet'
var scroll = Scroll.animateScroll

const scrollData = {
  ByCompany: 700,
  FreeInstance: 900,
  SmallEnterprise: 1500,
  MediumEnterprise: 3000,
  LargeEnterprise: 4300,
  ByRoles: 5700,
  Executives: 5900,
  Procurement: 7400,
  Finance: 8600,
}

const scrollToIndex = (location: string) => {
  const item = Object.entries(scrollData).find((item) => item[0] == location)
  if (item && item[1]) {
    scroll.scrollTo(item[1])
  }
}

const SupplierManagementWrapper = styled.div`
  body: {
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

const SupplierManagement = (props: any) => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '1-408-899-2224', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' Santa Clara',
      addressRegion: ' 2350 Mission College Boulevard, CA',
      postalCode: '95054',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  useEffect(() => {
    if (props.location.state && props.location.state.scroll) {
      scrollToIndex(props.location.state.scroll)
    }
  }, [])

  return (
    <Layout scrollToIndex={scrollToIndex}>
      <RDHelmet
        subtitle={'Supplier Management'}
        contentDescription="Raindrop’s Supplier Management solution enhances supplier collaboration, risk management, and performance evaluation while promoting supplier diversity and compliance."
        contentKeywords="content='Supplier Relationship Management (SRM), Supplier Management, Supplier Risk Management, Supplier Performance Evaluation, Supplier Diversity and Inclusion, Supplier Onboarding, Compliance Tracking, Spend Visibility, Supplier Collaboration Platform, Supplier Self-Service Portal, '"
        contentOG="AI Powered Source-to-Pay SaaS Platform | Try Raindrop Free"
        contentOgDescription="Raindrop’s Supplier Management solution enhances supplier collaboration, risk management, and performance evaluation while promoting supplier diversity and compliance."
        contentOgUrl="https://raindrop.com/solutions/SupplierManagement/"
        schemaMarkup={schemaMarkup}
      />
      <SupplierManagementWrapper>
        <AppSupplierManagementSolutionBanner />
        <AppSupplierManagementSolutionCard />
        <AppSupplierManagementSolutionCollapsible />
      </SupplierManagementWrapper>
    </Layout>
  )
}

export default SupplierManagement
